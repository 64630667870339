import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import BlogData from '../component/Data/blogData';
import Error from './Error';
import { BackVideo, Footer, Nav, Nav2 } from '../component';
import NoBlogs from './NoBlogs';
import BlogComp from '../component/BlogComp';
import "../assests/css/catblog.css";

const CatBlog = () => {
    const data = useParams();
    const blogData = BlogData;
    const filteredData = blogData.filter((blog) => {
        return blog.category === data.id;
    });

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const timeoutIds = filteredData.map((blog, index) => {
            return setTimeout(() => {
                setAnimate(prevState => ({ ...prevState, [index]: true }));
            }, 1000 * index);
        });

        return () => {
            timeoutIds.forEach(id => clearTimeout(id));
        };
    }, [filteredData]);

    
    if (filteredData.length === 0) {
        return <NoBlogs />;
    }

    return (
        <>
            <div className='CatBlog-container'>
                {filteredData.map((blog, index) => (
                    <div key={index} className={`blog-animation ${animate[index] ? 'animate-in' : ''}`}>
                        <BlogComp value={blog} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default CatBlog;
