import React, { useEffect } from 'react'
import "../assests/css/blogs.css"
import { Nav,Footer, Nav2 } from '../component'
import { Link } from 'react-router-dom'
import Learning from '../assests/images/blogs/learning.png'
import Preparation from '../assests/images/blogs/preparation.png'
const Blogs = () => {
    return (
        <div>
           <div className='blog-main-container'> 
                <h1 style={{fontFamily:"cursive"}}> &lt; Start <span style={{color:"blue"}}>Building </span>and <span style={{color:"blue"}}>Exploring</span>... /&gt;</h1>
                <div className='blog-main-box'>
                    <div className='blog-inner-container'>
                            <img src={Learning} alt=""/>
                            <Link to='/blogs/learning'><button>🡽 Start Learning...</button></Link>
                    </div>
                    <div className='blog-inner-container'>
                        <img src={Preparation} alt="" />
                        <Link to='/blogs/placement-prep'><button>🡽 Start Preparation...</button></Link>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default Blogs