import React from "react";
import htmlIMG from "../assests/images/skills/html.png";
import cssIMG from "../assests/images/skills/css.png";
import reactIMG from "../assests/images/skills/react.png";
import nodeIMG from "../assests/images/skills/nodejs.png";
import mongoIMG from "../assests/images/skills/mongo.png";
import pythonIMG from "../assests/images/skills/python.png";
import firebaseIMG from "../assests/images/skills/firebase.png";
import sqlIMG from "../assests/images/skills/sql.png";
import cppIMG from "../assests/images/skills/CPP.png"
import vIMG from "../assests/images/skills/gears/vscode.png"
import gIMG from "../assests/images/skills/gears/linux.png"
import lMG from "../assests/images/skills/gears/google.png"

const SkillsPage = () => {
    return <> <div className="skills-box1">
                 <h1>
                What I <span>do</span>
            </h1>
            <p>
                <span>&lt;</span> Crazy Developer who wants to explore every tech
                stack <span>/&gt;</span>
            </p>
            <div className="skills-inner-box1">
                <div className="skills-main-boxes">
                    <img src={htmlIMG} alt="" />
                    <p>html</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={cssIMG} alt="" />
                    <p>css</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={reactIMG} alt="" />
                    <p>reactJS</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={nodeIMG} alt="" />
                    <p>nodejs</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={pythonIMG} alt="" />
                    <p>python</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={mongoIMG} alt="" />
                    <p>mongoDB</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={sqlIMG} alt="" />
                    <p>sql</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={firebaseIMG} alt="" />
                    <p>firebase</p>
                </div>
                <div className="skills-main-boxes">
                    <img src={cppIMG} alt="" />
                    <p>C++</p>
                </div>
            </div>
        </div>
            <div className="skills-inner-box1">
            <h1>
                Tools <span> I use</span>
            </h1>
            <div className="gears-container">
                    <div className="gearsBox">
                            <img src={vIMG} alt="" />
                            <img src={lMG} alt="" />
                            <img src={gIMG} alt="" />
                    </div>
            </div>
            </div>
        </>
        
};

export default SkillsPage;
