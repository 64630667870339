import React from "react";
import {Nav, Nav2, BackVideo,AboutComp, Footer} from "../component"
import "../assests/css/about.css"


const About = () => {
  return  <div className="about-container">
                <AboutComp />
  </div>;
};

export default About;
