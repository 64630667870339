import React from "react";
import "../assests/css/undercons.css";
import image from "../assests/images/Error/undercons.svg";
import BackVideo from "./BackVideo";

const UnderCons = () => {
  return (
   <>
    <div className="Under-cons">
      <BackVideo />
      <div className="undercons-container">
      <img src={image} alt="loading" className="underConsImage" />
      <h1> 😔 currently UNDER CONSTRUCTION </h1>
      <h2>Sorry for the inconvenience!</h2>
    </div>
    </div>
   </>
  );
};

export default UnderCons;
