import React, { useEffect, useState } from 'react'
import resume from "../assests/images/resume/SouravSharmaResume.svg";
import resumeIMG from "../assests/images/resume/resume.svg"
import  "../assests/css/resume.css"
const Resume = () => {
  const [download, setDownlaod] = useState("Download Resume");

  const handleDownload = () =>  {
      const url = "https://drive.usercontent.google.com/download?id=1Ijrmg04XAWHLjhfKeqP8qXotc7D01ji5&export=download&authuser=2&confirm=t&uuid=2fcd7385-7ea5-487c-be25-46472fff0307&at=APZUnTWB4dBCIS1T-PGA3b_zlF6n:1721853097320";
      window.location.href = url;
      setDownlaod("please wait download will start shortly....");
      setInterval(()=>{
        setDownlaod("Download Resume");
      }, 4000)
    }
  return (
    <>
     <div className='resumeArea'>
          <img style={{
            backgroundColor:"white",
          }} src={resumeIMG} alt='loading resume...'></img>
          <button onClick={handleDownload}> {download} </button>
     </div>
    </>
  )
}

export default Resume