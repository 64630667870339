import React from "react";
import ProjectData from "./Data/projectData";
import ProjectBox from "./ProjectBox";
import { Link, useParams } from "react-router-dom";

const ProjectArea = () => {
  const Params = useParams();
  return(
    <>
     <div className="project-box1">     
      <h1>
         My Recent <span>Works</span>
      </h1>
     <p>Here are a few projects I've worked on recently.</p>
      <div className="project-inner-box1">
        {ProjectData.map((pro)=>(
         <ProjectBox key={pro.id} value={pro} />
        ))};
        </div>
      </div>
    </>
  )
};
export default ProjectArea;
