import React from 'react'
import ProjectData from './Data/projectData'
import { useParams } from 'react-router-dom';
import '../assests/css/fullproject.css'
const FullProject = () => {
    const data = ProjectData;
    const { id } = useParams();
    const filterData = data.find((project) => project.id === Number(id));

    if (!filterData) {
        return <p>Project not found</p>
    }

    const { heading, img, description, github_link, live_link, tech_used, features, duration, role, challenges_faced } = filterData;

    return (
        <div className="projectfull-details" style={{ color: "#f1f1f1", padding: "2rem", backgroundColor: "#1a1a1a" }}>
            <h1 style={{ fontSize: "2.5rem", marginBottom: "1rem" }}>{heading}</h1>
            <img src={img} alt={heading} width="100%" height="auto" style={{ marginBottom: "1.5rem", borderRadius: "10px" }} />
            
            <p style={{ fontSize: "1.2rem", lineHeight: "1.6" }}>{description}</p>

            <div className="project-links" style={{ margin: "1.5rem 0" }}>
                <a href={github_link} className="project-link" style={linkStyle} target="_blank" rel="noopener noreferrer">
                    🡽 View Project
                </a>
                <a href={live_link} className="project-link" style={linkStyle} target="_blank" rel="noopener noreferrer">
                    🡽 Live Demo
                </a>
            </div>

            <h3 style={{ fontSize: "1.6rem", marginTop: "1.5rem" }}>Technology Used:</h3>
            <p style={{ fontSize: "1.1rem", fontStyle: "italic" }}>{tech_used}</p>

            <h3 style={{ fontSize: "1.6rem", marginTop: "1.5rem" }}>Key Features:</h3>
            <ul style={{ fontSize: "1.2rem", listStyle: "disc", paddingLeft: "20px" }}>
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>

            <h3 style={{ fontSize: "1.6rem", marginTop: "1.5rem" }}>Duration:</h3>
            <p style={{ fontSize: "1.2rem" }}>{duration}</p>

            <h3 style={{ fontSize: "1.6rem", marginTop: "1.5rem" }}>Role:</h3>
            <p style={{ fontSize: "1.2rem" }}>{role}</p>

            <h3 style={{ fontSize: "1.6rem", marginTop: "1.5rem" }}>Challenges Faced:</h3>
            <p style={{ fontSize: "1.2rem" }}>{challenges_faced}</p>
        </div>
    );
}

const linkStyle = {
    display: "inline-block",
    backgroundColor: "#00aaff",
    color: "#fff",
    padding: "10px 20px",
    margin: "0 10px",
    borderRadius: "5px",
    textDecoration: "none",
    fontSize: "1.1rem",
};

export default FullProject;
