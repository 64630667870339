import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Contact, Error, Projects,Skills,About,Blogs, Resume} from "./Pages";
import {BackVideo, Footer, Nav, Nav2, UnderCons} from "./component"
import FullBlog from "./component/FullBlog";
import CatBlog from "./Pages/CatBlog";
import Learning from "./Pages/Learning";
import PlacementPrep from "./Pages/PlacementPrep";
import FullProject from "./component/FullProject";

function App() {
  return (
    <>
    <BackVideo/>
    <BrowserRouter>
       <Nav/>
       <Nav2/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/project/:id" element={<FullProject />} />
        <Route path="/blogs/placement-prep" element={<PlacementPrep />} />
        <Route path="/blogs/learning" element={<Learning />} />
        <Route path="/blogs/:id/:id" element={<CatBlog />} />
        <Route path="/blogs/:id/:id/:id" element={<FullBlog />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="*" element={<Error />} />
        <Route path="/unknown" element={<UnderCons />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;
