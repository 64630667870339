import React from "react";
import "../assests/css/footer.css";
const Footer = () => {
  return <div className="footer-container">
                <div className="footer-box">
                        <h1>DEVELOPED BY <span>SOURAV SHARMA</span></h1>
                        <h1>COPYRIGHT &#169; 2022-24</h1>
            </div>
    </div>;
};

export default Footer;
