import React from "react";
import "../assests/css/skills.css";
import {Footer, Nav,SkillsPage,Nav2, Socialhandle,BackVideo } from "../component";


const Skills = () => {
  return (
    <div className="skills-container">
      <Socialhandle />
      <SkillsPage />
    </div>
  );
};

export default Skills;
