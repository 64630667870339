import React from 'react'
import { Nav, Nav2, Socialhandle,BackVideo, Footer, ResumeArea } from "../component";

const Resume = () => {
  return (
    <>
    <ResumeArea />
    </>
  )
}

export default Resume