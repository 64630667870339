import React from 'react'
import "../assests/css/learning.css"
import { Nav, Footer, Nav2, BackVideo } from '../component'
import { Link } from 'react-router-dom'
import htmlIMG from "../assests/images/skills/html.png";
import cssIMG from "../assests/images/skills/css.png";
import reactIMG from "../assests/images/skills/react.png";
import nodeIMG from "../assests/images/skills/nodejs.png";
import mongoIMG from "../assests/images/skills/mongo.png";
import pythonIMG from "../assests/images/skills/python.png";
import firebaseIMG from "../assests/images/skills/firebase.png";
import sqlIMG from "../assests/images/skills/sql.png";
import jsImg from "../assests/images/skills/jsIMG.png"
import cyberImg from "../assests/images/skills/cyberIMG.png"
import serverImg from "../assests/images/skills/serverIMG.png"

const Learning = () => {
  return (
    <>

      <div className="blogs-box1">
        <div className="blogs-inner-box1">
          <h1> &lt; Let's learn / &gt;</h1>
          <div className='blogs-box-container'>
            <Link className='link-styele' to={`html`}>
              <div className="card">
                <img src={htmlIMG} alt="" />
                <h1> HTML </h1>
              </div>
            </Link>
            <Link className='link-styele' to={`css`}>
              <div className="card">
                <img src={cssIMG} alt="" />
                <h1>CSS  </h1>
              </div>
            </Link>
            <Link className='link-styele' to={`javascript`}>
              <div className="card">
                <img src={jsImg}alt="" />
                <h1>   JavaScript </h1>
              </div>
            </Link>
            <Link className='link-styele' to={`react`}>
              <div className="card">
                <img src={reactIMG} alt="" />
                <h1> React </h1>
              </div>
            </Link>
            <Link className='link-styele' to={`database`}>
              <div className="card">
                <img src={sqlIMG} alt="" />
                <h1> Database</h1>
              </div>
            </Link>
            <Link className='link-styele' to={`cybersecurity`}>
              <div className="card">
                <img src={cyberImg} alt="" />
                <h1>  CyberSecurity </h1>
              </div>
            </Link>
            <Link className='link-styele' to={`server`}>
              <div className="card">
                <img src={serverImg} alt="" />
                <h1>  Servers  </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Learning