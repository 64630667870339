import React, { useEffect, useState } from "react";
import "../assests/css/home.css";
import banner from "../assests/images/home/bg2.gif";
import cctv from "../assests/images/home/7VB.gif";
import { ContactComp, Nav,ProjectArea, SkillsPage,Nav2,AboutComp, Socialhandle,SocialRes, BackVideo, Footer } from "../component";
import {useRef} from 'react';
import { letterSpacing } from "@mui/system";
import TypewriterComponent from "typewriter-effect";

const Home = () => {
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  const ref2 = useRef(null);
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({behavior: 'smooth'});
  };

  // const myName = "Sourav Sharma";
  // const [name, setName] = useState("");
  // useEffect(() => {
  //   let curr = 0;
  //   setName('')
  //   const interval = setInterval(() => {
  //     if (curr < myName.length) {
  //       setName(myName.slice(0, ++curr));
  //     } else {
  //       clearInterval(interval);
  //     }
  //   }, 300);
  //   return () => {clearInterval(interval)}; 
  // }, []);
 

    
  return (
    <div className="home-container">
      <Socialhandle />
          <div className="home-box2">
            <div className="home-inner-box1">
               <div className="intro-box">
                    <h1 style={{fontSize:"3rem"}}>
                      <TypewriterComponent
                       options={{
                        strings: [],
                        autoStart: true,
                        loop: true,
                        delay: 200
                      }}
                      onInit={(typerwriter) => {
                        typerwriter.typeString('Hy! I Am <br> <span style="color: white; font-size: 3rem; font-weight: bold;">Sourav Sharma</span><br>')
                          .pauseFor(300)
                          .typeString('<i style="color: rgb(96, 96, 241); font-size: 2rem;">A Full Stack Developer!</i>')
                          .pauseFor(200)
                          .deleteChars(23)
                          .typeString('<i style="color: white; font-size: 2rem;">and a passionate programmer!</i>')
                          .pauseFor(200)
                          .deleteChars(28)
                          .typeString('<strong style="color: rgb(96, 96, 241); font-size: 2rem; font-weight: bold;">Explore how I bring ideas to life through code!</strong>')
                          .pauseFor(200)
                          .start();
                      }}
                          
                            />
                        </h1>

                    <button className="hireMe" onClick={handleClick}>Hire Me</button>
                    <button className="knowMore" onClick={handleClick2}>Know More</button>
               </div>
            </div>
          <div className="home-inner-box2">
                  <div className="image-box">
                  <img src={banner} alt="" />
            </div>
        </div>
      </div>
      <SocialRes />
      <ProjectArea/>
      <SkillsPage />
      <div ref={ref2}>
      <AboutComp />
      </div>
      <div ref={ref}> 
      <ContactComp /> 
      </div>
    </div> 
  );
};

export default Home;
