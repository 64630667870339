import React from "react";
import aboutIMG from "../assests/images/about/about-image.svg"

const AboutComp = () => {
  return <div className="about-inner-box">
          <div className="about-box1">
                        <div className="about-inner-box1">
                                   <div className="whoiam-box">
                                   <h1>Who <span>I'm</span></h1>
                                    </div> 
                                   <h3>Hi <span> Everyone! </span> I am Sourav Sharma from <span>Himachal Pradesh, India</span> <br/> I'm currently pursuing my <span>Bachelor of Engineering</span> in Computer Science from <span>Chitkara University (Punjab)</span></h3>
                                   
                        </div>
                        <div className="about-inner-box2">
                                <img src={aboutIMG} alt="Loading..." />
                        </div>
                </div>
  </div>;
};

export default AboutComp;
