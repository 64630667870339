import React from "react";
import {useState} from 'react'
import banner2IMG from "../assests/images/contact/banner2.svg"

const ContactComp = () => {
     const sub = document.getElementById('sub');
     const formid = document.getElementById('formid');
    const [data, setData] = useState({
        name: "",
        email: "",
        message: "",
      })
      const { name, email, message } = data;
    
      const handleChange = (e) =>
      setData({ ...data, [e.target.name]: e.target.value })
       
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        const response =  await fetch("https://v1.nocodeapi.com/souravsharma000/google_sheets/OLAmpnDAReCxLEyp?tabId=Sheet1", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify([
              [new Date().toLocaleString(),name, email, message]
            ]),
          }
        );
          await response.json();
          sub.style.display = "block";
          sub.style.color = "gold";
          formid.style.display = "none";
          setData({ ...data, name:"", email:"",message:""}); 
        } catch (err) {
          console.log(err)
        }
      };


      const handleEvent = () =>{

      }
  return <div className="contact-area">
             <h1>
            <span> &lt; </span>  Let's <span>start</span> something great <span>/&gt;</span>
            </h1>
            <p>
                <span></span>  <span></span>
            </p>
            <div className='contact-box1'>

            <div className='contact-inner-box2'>
                 <img src={banner2IMG} alt="" />
            </div>
            <div className='contact-inner-box1'>
                 <div className='contact-form'>

                 <h1>Love to hear from you <br></br><span>Get in touch</span> 👋</h1>
                 <h1 type='submit' id="sub" style={{display:"none"}} > Your message has been sent successfully... <i class="fa-solid fa-check"></i></h1>
                <form method='' name='contact-us' id="formid" onSubmit={handleSubmit}>
                <label htmlFor='name'>Your name <sperscript>*</sperscript></label>
                <input type="text" name='name' value={name} onChange={handleChange} placeholder='yourname' required />
                <label htmlFor='email'  required>Your email  <sperscript>*</sperscript></label>
                <input type="email" name='email' value={email} onChange={handleChange} placeholder='youremail@gmail.com' required />
                <p>Message  <sperscript>*</sperscript></p>
                <textarea cols="10" rows="3" name="message" value={message} onChange={handleChange} placeholder='Type your message here' required></textarea>
                <button type='submit' onClick={handleEvent}> Just Send 🡕 </button>
            </form>
      </div>
    </div>
  
    </div>
</div>;
};

export default ContactComp;
