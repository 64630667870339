import React from 'react'
import "../assests/css/blogComp.css"
import { Link, useParams } from 'react-router-dom';
import BlogData from './Data/blogData';
import "../assests/css/blogComp.css"
import logo from "../assests/images/home/profile.jpeg"
const BlogComp = ({value}) => {
    const data = useParams();
    const blogData = BlogData;
    const filteredData = blogData.filter((blog)=>{
        return blog.category === data.id;
    })
  return (
    <>
        <div className='blogComp-container'>
            <div className='first'>
                <img src={value.image[0]} alt="" />
                </div>
            <div className='second'>
                    <div className='blog-profile'>
                        <h2>Author : </h2>
                        <img src={logo} alt="" />
                        <h2>{value.author}</h2>
                        <h2>|</h2>
                        <h2>{value.date}</h2>
                    </div>
                <h1 className='title'>{value.title}</h1>
                <h2>{value.description}</h2>
                <Link className='viewMore_blog'  to= {`/blogs/learning/${data.id}/${(value.title.toLowerCase().replace(/ /g, '-'))}`} ><h1>View more...</h1></Link>
                <h2>Tags : <i> {value.tags.join(", ")}</i></h2>
            </div>
        </div>
    </>
  )
}

export default BlogComp