import React from 'react'
import { Footer, Nav, Nav2, UnderCons } from '../component'
import '../assests/css/placement.css'
const PlacementPrep = () => {
  return (
    <>
    <div className='placement-prep-container'>
        <UnderCons/>
    </div>
    </>
  )
}

export default PlacementPrep