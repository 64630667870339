import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProjectBox = ({ value }) => {
  const [showMore, setShowMore] = useState(false);
  const displayDescription = showMore ? value.description : value.description.slice(0, 200);

  return (
    <div className="project-data-box">
      <img src={value.img} alt="" />
      <h1>{value.heading}</h1>
      <p>
        {displayDescription}...
          <>
            <Link to={`/project/${value.id}`} className='projectView'>View more</Link>
          </>
      </p>
      <div className="link-section">

      <a href={value.github_link} className="project-link" target="_blank" rel="noopener noreferrer">
        🡽 View Project
      </a>
      <a href={value.live_link} className="project-link" target="_blank" rel="noopener noreferrer">
        🡽 Live
      </a>
      </div>
      <h3>
        Technology used: <span>{value.tech_used}</span>
      </h3>
    </div>
  );
};

export default ProjectBox;
