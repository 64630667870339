import image1 from "../../assests/images/projects/carpool.png"
import image2 from "../../assests/images/projects/chatting.png"
import image3 from "../../assests/images/projects/portfolio.png"
import image4 from "../../assests/images/projects/weather.png"
import image5 from "../../assests/images/projects/todo.png"
import image6 from "../../assests/images/projects/ecommerce.png"
import image7 from "../../assests/images/projects/youtube.png"

const ProjectData = [
    {
        id: 1,
        img: image1,
        heading: "A Carpooling Application",
        description: "TripSync is a user-friendly carpooling application designed to make your daily commute more convenient, affordable, and eco-friendly. With TripSync, you can easily find and connect with nearby commuters who share your route and schedule.",
        github_link: "https://github.com/the-code-breaker/TripSync",
        live_link: "https://tripsync.onrender.com/",
        tech_used: "Vitejs, NodeJs, MongoDB, CUI, MUI, Google-Api, RazorPay Payment Gateway, Render.com",
        features: ["Real-time carpooling matches", "Payment integration", "Live route tracking", "User ratings & reviews"],
        duration: "3 months",
        role: "Full Stack Developer",
        challenges_faced: "Implementing real-time route tracking and ensuring data consistency with large user traffic."
    },
    {
        id: 2,
        img: image2,
        heading: "Chatting App",
        description: "Walky Talky is a modern chatting application created with Vite.js, MongoDB, and Node.js. It enables users to create accounts and engage in seamless real-time conversations with each other, fostering interactive and personalized communication experiences.",
        github_link: "https://github.com/the-code-breaker/WalkyTalkyChatApp/",
        live_link: "https://walkytalky-r0fb.onrender.com/",
        tech_used: "Vitejs, NodeJs, MongoDB, Cloudinary, Chakra UI, Render.com",
        features: ["Real-time messaging", "File sharing", "Cloud image storage", "Private & group chats"],
        duration: "2 months",
        role: "Backend Developer",
        challenges_faced: "Ensuring smooth message delivery under heavy loads and optimizing real-time updates."
    },
    {
        id: 3,
        img: image3,
        heading: "Portfolio Site",
        description: "A personal portfolio website showcasing my skills, projects, and passion for web development using HTML, CSS, JavaScript, and ReactJS. It features a clean and modern design to highlight both content and user experience.",
        github_link: "#",
        live_link: "#",
        tech_used: "HTML, CSS, JavaScript, ReactJS",
        features: ["Responsive design", "Animated sections", "Project gallery", "Contact form integration"],
        duration: "1 month",
        role: "Frontend Developer",
        challenges_faced: "Implementing smooth animations and ensuring cross-browser compatibility."
    },
    {
        id: 4,
        img: image4,
        heading: "Weather App",
        description: "A weather application built using HTML, CSS, JavaScript, and ReactJS, powered by a Weather API. It provides real-time weather updates for any location with a sleek user interface.",
        github_link: "https://github.com/the-code-breaker/React-Weather-App",
        live_link: "https://weather-app-db432.web.app/",
        tech_used: "HTML, CSS, JavaScript, ReactJS, Weather API",
        features: ["Current & forecast weather", "Geolocation API", "Unit conversion (Celsius/Fahrenheit)", "Dynamic background updates"],
        duration: "2 weeks",
        role: "Frontend Developer",
        challenges_faced: "Handling API limitations and dynamically updating weather information efficiently."
    },
    {
        id: 5,
        img: image5,
        heading: "To Do App",
        description: "A task management tool that helps users organize their daily tasks efficiently. Built using HTML, CSS, JavaScript, and ReactJS, the app provides a minimalist design for an intuitive user experience.",
        github_link: "https://github.com/the-code-breaker/React-To-Do-App",
        live_link: "https://to-do-app-524f6.web.app/",
        tech_used: "HTML, CSS, JavaScript, ReactJS",
        features: ["Task creation & editing", "Task prioritization", "Task completion tracking", "Responsive design"],
        duration: "1 month",
        role: "Full Stack Developer",
        challenges_faced: "Implementing real-time task updates and ensuring a smooth UI on all screen sizes."
    },
    {
        id: 6,
        img: image6,
        heading: "E-Commerce Website",
        description: "An eCommerce website offering a seamless shopping experience. Built with HTML, CSS, and JavaScript, it showcases featured products and promotions with an easy-to-navigate design.",
        github_link: "https://github.com/the-code-breaker/E-Commerce-Website",
        live_link: "https://the-code-breaker.github.io/E-Commerce-Website/index.html",
        tech_used: "HTML, CSS, JavaScript",
        features: ["Product listing", "Search functionality", "Shopping cart", "Responsive design"],
        duration: "2 months",
        role: "Frontend Developer",
        challenges_faced: "Ensuring smooth cart functionality and implementing a user-friendly search filter."
    },
    {
        id: 7,
        img: image7,
        heading: "YouTube Video Downloader",
        description: "A YouTube video downloader built using ReactJS. Users can download videos by pasting the URL, offering a user-friendly interface with quick download options.",
        github_link: "https://github.com/the-code-breaker/YouTube_Video_Downloader",
        live_link: "https://video-downloader-bf15d.web.app/",
        tech_used: "ReactJS",
        features: ["URL-based video download", "Multiple resolution options", "Simple UI", "Responsive design"],
        duration: "1 month",
        role: "Frontend Developer",
        challenges_faced: "Handling various video formats and ensuring fast download speeds."
    },
];

export default ProjectData;
