import React from 'react'
import { Footer, Nav, Nav2 } from '../component'
import "../assests/css/error.css"
const NoBlogs = () => {
  return (
    <>
    <div className='noblog'>
      <h1 style={{color:"white", textAlign:"center"}}>No Blogs for this Category</h1>;
    </div>
    </>
  )
}

export default NoBlogs