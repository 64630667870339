import React from 'react'
import "../assests/css/contact.css"
import { BackVideo, Footer} from '../component'
import { Nav,Nav2,ContactComp } from '../component'

const Contact = () => {
  return (
    <div className='contact-container'>
        <ContactComp />
    </div>
  )
} 

export default Contact
