import React from "react";
import "../assests/css/backvideo.css"
import backvideo from "../assests/background-video/background.mp4"

const BackVideo = () => {
  return <div className="backvideo-container">
            <div className="backvideo-box">
                    <video muted loop autoPlay>
                            <source src={backvideo} ></source>
                    </video>

            </div>
  </div>;
};

export default BackVideo;
