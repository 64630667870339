import React from 'react';
import { useParams } from 'react-router-dom';
import BlogData from './Data/blogData';
import Nav from './Nav';
import Nav2 from './Nav2';
import Footer from './Footer';
import logo from "../assests/images/home/profile.jpeg";
import '../assests/css/fullBlogs.css';
import { FaXTwitter } from "react-icons/fa6";
import { CiFacebook } from "react-icons/ci";
import BackVideo from './BackVideo';

const FullBlog = () => {
    const { id } = useParams();
    const filteredData = BlogData.filter((blog) => {
        return `${blog.title.toLowerCase().replace(/ /g, '-')}` === id;
    });

    if (filteredData.length === 0) {
        return (
            <>
                <div className='fullBlogContainer'>
                    <h1>Blog Post Not Found</h1>
                </div>
                <Footer />
            </>
        );
    }

    const blogData = filteredData[0];

    return (
        <>
            <div className='fullBlogContainer'>
                <div className='blog-profile'>
                    <h3>Author:</h3>
                    <img src={logo} alt='' />
                    <h4>{blogData.author}</h4>
                    <h3>|</h3>
                    <h4>{blogData.date}</h4>
                </div>
                <div className='heading'>
                    <h1>{blogData.title}</h1>
                </div>
                <div className='main-blog'>
                    <img src={blogData.image[0]} alt='' />
                    <p>{blogData.description}</p>
                    {blogData.image[1] && <img src={blogData.image[1]} alt='' />} {/* Render second image if available */}
                    <p>{blogData.fullDescription}</p>
                </div>
                <div className='tags'>
                    <h3>Tags:</h3>
                    <p>{blogData.tags.join(', ')}</p>
                </div>
                <div className='related-articles'>
                    <h3>Related Articles:</h3>
                    <ul>
                        {blogData.relatedArticles.map((article) => (
                            <li key={article.id}>
                                <a href={article.link} target='_blank' rel='noopener noreferrer'>{article.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='social-media-links'>
                    <h3>Share:</h3>
                    <ul>
                        <li>
                            <a href={blogData.socialMediaLinks.twitter} target='_blank' rel='noopener noreferrer'>
                            <FaXTwitter /> 
                            </a>
                        </li>
                        <li>
                            <a href={blogData.socialMediaLinks.facebook} target='_blank' rel='noopener noreferrer'>
                            <CiFacebook />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default FullBlog;
