import React, {useState, useEffect} from 'react'
import '../assests/css/resnav.css'
import { Link } from 'react-router-dom';
import giflogo from "../assests/images/home/profile.gif"

export default function Navbar() {
  const[toggle, setToggle] = useState(true);
 const handletoggle = () => {
    setToggle(!toggle);
 }

  return (
    <>
    <nav id='inner-nav'>
 <div>
  
 </div>
 <body >
  
 </body>
 <div style={{
      color:"white",
      display:"flex",
      justifyContent:"flex-start",
      gap:"1rem",
      alignItems:"center",
      fontFamily: "Roboto",
      fontWeight: "800",
      fontStyle: "normal",
      padding: "0.4rem 0.1rem"
 }}>
         <img src={giflogo} alt="" className='nav-res-logo' />
         <h3 className='nav-res-heading'>  Sourav Sharma | Web Developer  </h3>
 </div>
       
        <label htmlFor="toggle" id='label-toggle' onClick={handletoggle}>{toggle ? <i class="fa-solid fa-bars"></i> : <i class="fa-solid fa-xmark"></i>}</label>
        <input type="checkbox" name='toggle' id='toggle'/>
        <ul className="list">
          
        <Link to="/" style={{ textDecoration: 'none' }}  onClick={() => {
                    document.getElementById('toggle').checked = false
                      handletoggle()}} ><li className="items"><i class="fa-solid fa-house"></i>Home</li></Link>
        <Link to="/projects" style={{ textDecoration: 'none' }} onClick={() => {
                    document.getElementById('toggle').checked = false
                      handletoggle()}} >  <li className="items"><i class="fa-solid fa-diagram-project"></i>Projects</li></Link>
        <Link to="/skills" style={{ textDecoration: 'none' }} onClick={() => {
                    document.getElementById('toggle').checked = false
                      handletoggle()}} >  <li className="items"><i class="fa-solid fa-kitchen-set"></i>Skills</li></Link>
        <Link to="/about" style={{ textDecoration: 'none' }} onClick={() => {
                    document.getElementById('toggle').checked = false
                      handletoggle()}} > <li className="items"><i class="fa-regular fa-address-card"></i>About</li></Link>
        <Link to="/resume" style={{ textDecoration: 'none' }} onClick={() => {
                    document.getElementById('toggle').checked = false
                      handletoggle()}} > <li className="items"><i class="fa-solid fa-file"></i>Resume</li></Link>
        <Link to="/blogs" style={{textDecoration:'none'}} onClick={() => {
                    document.getElementById('toggle').checked = false
                      handletoggle()}} ><li className="items"><i class="fa-brands fa-blogger-b"></i>Blogs</li></Link>
        <Link to="/contact" style={{ textDecoration: 'none' }} onClick={() => {
                    document.getElementById('toggle').checked = false
                      handletoggle()}} > <li className="items"><i class="fa-regular fa-address-book"></i>Contact</li></Link>
    </ul>

    </nav>
    </>
    
  )
}

