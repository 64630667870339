import React from "react";
import "../assests/css/socialres.css";
import facebook from "../assests/images/social/facebook.png";
import instagram from "../assests/images/social/instagram.png";
import linkedin from "../assests/images/social/linkedin.png";
import github from "../assests/images/social/github.png";
import youtube from "../assests/images/social/youtube.png";
import X from "../assests/images/social/x.png";
import findme from "../assests/images/social/findme.svg";


const SocialRes = () => {
  return <div className="socialres-container">
            <div className="socialres-box">
                <img src={findme} alt="" />
                <h1>Find me <span>on</span></h1>
                <p></p>
                <div className="socialres-data-box">
                <a href="https://www.facebook.com/profile.php?id=100011405194529" target="_blank_"><img src={facebook} alt="facebook" /></a>
                        <a href="https://www.instagram.com/s0urav.co/" target="_blank_"><img src={instagram} alt="instagram" /></a>
                        <a href="https://www.linkedin.com/in/thecodebreaker/" target="_blank_"><img src={linkedin} alt="linkedin" /></a>
                        <a href="https://github.com/the-code-breaker" target="_blank_"><img src={github} alt="github" /></a>
                        <a href="https://www.youtube.com/c/LimitlessSourav/" target="_blank_"><img src={youtube} alt="youtube" /></a>
                        <a href="https://twitter.com/theecodebreaker" target="_blank_"><img src={X} alt="x" id="x" /></a>
                </div>
            </div>
  </div>;
};

export default SocialRes;
