import React from 'react'
import homeicon from "../assests/images/home/icon/home-icon.png";
import abouticon from "../assests/images/home/icon/about-icon.png";
import workicon from "../assests/images/home/icon/work-icon.png";
import skillsicon from "../assests/images/home/icon/skills.png";
import blogsicon from "../assests/images/home/icon/blogs.png";
import resumeicon from "../assests/images/home/icon/resume.png";
import { Link } from "react-router-dom"
import giflogo from "../assests/images/home/profile.gif"
const Nav = () => {
  return (
    <div className="home-box1 nav">
          <ul>
            <img src={giflogo} alt="loading.." className="nav-logo" />
              <Link to="/" style={{ textDecoration: 'none' }} >   <li><span>   <img src={homeicon} alt="" /> Home      </span>   </li></Link>
              <Link to="/projects" style={{ textDecoration: 'none' }}> <li> <span>  <img src={workicon} alt="" />Projects    </span>    </li></Link>
              <Link to="/skills" style={{ textDecoration: 'none' }}><li> <span>  <img src={skillsicon} alt="" />Skills     </span> </li> </Link>
              <Link to="/about" style={{ textDecoration: 'none' }}> <li> <span>   <img src={abouticon} alt="" />About  </span></li> </Link> 
              <Link to="/resume" style={{ textDecoration: 'none' }}> <li> <span>   <img src={resumeicon} alt="" />Resume  </span></li> </Link> 
              <Link to="/blogs" style={{ textDecoration: 'none' }}> <li> <span>   <img src={blogsicon} alt="" />Blogs  </span></li> </Link> 
              <Link to="/contact" style={{ textDecoration: 'none' }}>    <button className="nav-button"> 💘 Contact Us </button> </Link>
           </ul>
           <div>
           </div>
        </div>
  )
}

export default Nav