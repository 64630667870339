import React from "react";
import { Nav,ProjectArea, Nav2, Socialhandle,BackVideo, Footer } from "../component";
import "../assests/css/projects.css";

const Projects = () => {
  return (
    <div className="project-container">
      <Socialhandle />
      <ProjectArea />
    </div>
  );
};

export default Projects;
