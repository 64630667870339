import React from "react";
import "../assests/css/error.css"
import errorImage from '../assests/images/Error/pagenotfound.svg';
import Nav from "../component/Nav";
import Nav2 from "../component/Nav2";
import BackVideo from "../component/BackVideo";
import { Link } from "react-router-dom"

const Error = () => {
    return (
       <>
       <div className="error-container">
              <img src={errorImage} alt="" />
              <h1> <span> 🥴 </span> Oops you went wrong!</h1>
              <h1>requested page not found..</h1>
           </div>
       </>
  );
};

export default Error;
